import ServerUtils from '@root/core/src/api/server-utils';
import omit from '@root/vendor/lodash/omit';

export async function driveScienceUserLogin(params) {
  const { data } = await ServerUtils.unauthenticatedPost('/drive_science_api/sessions', {
    body: {
      ...params,
    },
    expectedResponse: 201,
    expectedErrorResponses: [401],
  });
  return data;
}

export async function driveScienceUserForgotPassword(params) {
  return await ServerUtils.unauthenticatedPost('/drive_science_api/password_reset', {
    body: {
      password_reset: {
        ...params,
      },
    },
    expectedResponse: 204,
    expectedErrorResponses: [422],
  });
}

export async function driveScienceUserPasswordReset(params) {
  const { data } = await ServerUtils.unauthenticatedPatch('/drive_science_api/password_reset', {
    body: {
      password_reset: {
        ...params,
      },
    },
    expectedResponse: 200,
    expectedErrorResponses: [422],
  });

  return data;
}

export async function validateSessionToken() {
  const { data } = await ServerUtils.authenticatedGet('/drive_science_api/sessions/validate_token', {
    expectedResponse: 200,
  });
  return data;
}

export async function getDriver(id) {
  const { data } = await ServerUtils.authenticatedGet(`/drive_science_api/drivers/${id}`, {
    expectedResponse: 200,
  });
  return data;
}

export async function getDrivers(params) {
  const { data } = await ServerUtils.authenticatedGet('/drive_science_api/drivers', {
    query: {
      ...params,
    },
    expectedResponse: 200,
  });
  return data;
}

export async function getReportUrl(id) {
  const { data } = await ServerUtils.authenticatedGet(`/drive_science_api/reports/${id}/download`, {
    expectedResponse: 200,
    expectedErrorResponses: [401, 404], /* eslint root/dont-allow-404-response: 0 */
  });
  return data;
}

export async function editDriver(params) {
  return await ServerUtils.authenticatedPut(`/drive_science_api/drivers/${params.id}`, {
    body: {
      ...omit(params, 'id'),
    },
    expectedResponse: 200,
    expectedErrorResponses: [401],
  });
}

export async function getDriverList(asOfDate = null) {
  let url = '/drive_science_api/driver_deactivation_states/download';
  if (asOfDate) {
    url += `?as_of_date=${asOfDate}`;
  }

  const { data } = await ServerUtils.authenticatedGet(url, {
    expectedResponse: 200,
    expectedErrorResponses: [401, 400],
  });

  return data.data;
}

export async function uploadDriversList(formData) {
  const url = '/drive_science_api/driver_deactivation_states/upload';

  const { data } = await ServerUtils.authenticatedPost(url, {
    body: formData,
    expectedResponse: 200,
    expectedErrorResponses: [401, 400],
  });

  return data;
}
