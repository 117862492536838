import LoginForm from '@root/root-enterprise.com/src/components/login-form';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import RouteLink from '@root/core/src/components/route-link';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function Login({
  clientId = '', forgotPasswordPath, onLogin,
}) {
  const fullForgotPasswordPath = clientId && clientId !== '' ? `${forgotPasswordPath}?client_id=${clientId}` : forgotPasswordPath;

  return (
    <div css={styles.container}>
      <h1>Log into Root Enterprise</h1>
      <LoginForm
        clientId={clientId}
        onLogin={onLogin}
      />
      <div css={styles.linksContainer}>
        <div css={styles.linkContainer}>
          <RouteLink
            css={styles.link}
            to={fullForgotPasswordPath}
          >
            Forgot password?
          </RouteLink>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 63,
  },
  linksContainer: {
    marginTop: '10px',
  },
  linkContainer: {
    ...Theme.paragraph1({
      bold: true,
    }),
    textAlign: 'center',
    marginTop: '30px',
  },
  link: {
    ...Theme.boldLink(),
  },
});

Login.propTypes = {
  clientId: PropTypes.string,
  forgotPasswordPath: PropTypes.string.isRequired,
  onLogin: PropTypes.func.isRequired,
};
