import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import x from '@root/root-enterprise.com/src/assets/x.png';
import { StyleSheet } from '@root/core/src/utils/styles';
import { sectionWithHeaderSeparatorStyles } from '@root/root-enterprise.com/src/utils/section-styles';

export default function NotificationTextRow({
  image, text, onClick,
}) {
  const styles = sectionWithHeaderSeparatorStyles;

  return (
    <tr>
      <td />
      <td css={customStyles.data}>
        <div css={[styles.tableColumnContainer, customStyles.dataDiv]}>
          <img
            css={styles.tableColumnContents}
            src={image}
          />
          <p css={[styles.tableColumnContents, customStyles.textContent]}>{text}</p>
          <img
            css={styles.tableColumnContents}
            onClick={onClick}
            src={x}
          />
        </div>
      </td>
    </tr>
  );
}

export const customStyles = StyleSheet.create({
  data: {
    paddingLeft: '5%',
  },
  dataDiv: {
    borderRadius: 10,
    border: '1px solid #E5E5E5',
    width: 350,
  },
  textContent: {
    width: 300,
  },
});

NotificationTextRow.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};
