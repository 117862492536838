import AuthService from '@root/core/src/services/auth-service';
import NetworkService from '@root/core/src/services/network-service';
import { driveScienceUserLogin } from '@root/root-enterprise.com/src/api/root-server';

export async function loginUser(clientId, email, password) {
  const result = await NetworkService.request(driveScienceUserLogin, {
    clientId,
    email,
    password,
  });

  if (result.isSuccess) {
    AuthService.setAccessToken(result.data.token);
  }

  return result;
}
