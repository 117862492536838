import PropTypes from '@root/vendor/prop-types';
import React, { useRef } from '@root/vendor/react';
import moment from 'moment';
import { BUTTON_SIZE_SMALL, BUTTON_VARIANT_PRIMARY } from '@root/core/src/components/button/styles';
import { Button } from '@root/core/src/components/button/button';
import { CSVLink } from 'react-csv';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ResultsCsvDownloadButton({ data, resetResultsData }) {
  const csvLinkRef = useRef();

  const onClick = () => {
    csvLinkRef.current.link.click();
    resetResultsData();
  };

  const filename = () => {
    const formattedDate = moment().format('YYYYMMDD');

    return 'driver_billing_status_update_result_' + formattedDate + '.csv';
  };

  return (
    <div
      css={styles.button}
      data-testid={'results-csv-download-button'}
    >
      <Button
        onClick={onClick}
        size={BUTTON_SIZE_SMALL}
        variant={BUTTON_VARIANT_PRIMARY}
      >
        Download
      </Button>
      <CSVLink
        className={'hidden'}
        data={data}
        filename={filename()}
        ref={csvLinkRef}
        target={'_blank'}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 200,
    paddingBottom: 10,
  },
});

ResultsCsvDownloadButton.propTypes = {
  data: PropTypes.string,
  resetResultsData: PropTypes.func,
};
