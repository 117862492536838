import Lottie from 'lottie-web/build/player/lottie.min';
import LottieLight from 'lottie-web/build/player/lottie_light.min';

export const opacityKeyframes = {
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
};

export { LottieLight, Lottie };
