import Button from '@root/core/src/components/button';
import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { editDriver } from '@root/root-enterprise.com/src/api/root-server';
import { isValidEmail, isValidPhoneNumber } from '@root/core/src/utils/validators';
import { useState } from '@root/vendor/react';

const validations = [
  isValidEmail('email'),
  isValidPhoneNumber('phone'),
];

export default function EditDriverForm({
  driver, onCancel, onSubmit,
}) {
  const [isError, setIsError] = useState(false);
  const form = useForm({
    validations,
    initialValues: {
      email: driver?.email,
      phone: driver?.phone,
    },
  });

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    const email = form.getFieldProps('email').value;
    const phone = form.getFieldProps('phone').value;

    const result = await NetworkService.request(editDriver, {
      id: driver.id,
      email,
      phone,
    });

    if (result.isSuccess) {
      onSubmit();
    } else {
      setIsError(true);
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <ErrorSummary
        heading={'Something went wrong while updating the driver.'}
        message={'Please try again.'}
      />
    );
  }

  return (
    <div css={styles.container}>
      <form
        onSubmit={form.createSubmitHandler(submit)}
      >
        {errorSummary}
        <div css={styles.inputWrapper}>
          <Input
            autoCapitalize={'off'}
            id={'email'}
            inputType={TextTypes.TEXT}
            label={'Email'}
            {...form.getFieldProps('email')}
          />
        </div>
        <Input
          autoCapitalize={'off'}
          id={'phone'}
          inputType={TextTypes.TEL}
          label={'Phone'}
          {...form.getFieldProps('phone')}
        />
        <div css={styles.driverId}>Driver ID: {driver.id}</div>
        <div css={styles.buttonWrapper}>
          <LoaderButton
            isLoading={form.submitting}
            loadingText={'Saving...'}
          >
            Save
          </LoaderButton>
          <Button
            css={styles.cancelButton}
            onClick={onCancel}
            type={'button'}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-around',
  },
  cancelButton: {
    backgroundColor: Colors.gray40(),
    ':active': {
      backgroundColor: Colors.gray40(),
    },
  },
  container: {
    width: 380,
  },
  driverId: {
    marginTop: 15,
  },
  inputWrapper: {
    marginBottom: 10,
  },
});

EditDriverForm.propTypes = {
  driver: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    riskScore: PropTypes.object,
    hasTrips: PropTypes.bool,
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
