import DownloadReport from '@root/root-enterprise.com/src/scenes/download-report';
import Drivers from '@root/root-enterprise.com/src/scenes/drivers';
import EditDriver from '@root/root-enterprise.com/src/scenes/edit-driver';
import PropTypes from '@root/vendor/prop-types';
import { Redirect, Route, Switch } from '@root/core/src/components/route';

export default function ProtectedRouter({ redirectRoute }) {
  return (
    <Switch>
      <Route
        path={'/drivers'}
        render={() => <Drivers />}
      />
      <Route
        path={'/driver/:id/edit'}
        render={({ match }) => {
          return (
            <EditDriver
              {...match.params}
            />
          );
        }}
      />
      <Route
        path={'/reports/:reportId/download'}
        render={({ match }) => {
          return (
            <DownloadReport
              {...match.params}
            />
          );
        }}
      />
      {redirectRoute && redirectRoute.match(/reports\/.+\/download/) ? <Redirect to={redirectRoute} /> : <Redirect to={'/drivers'} />}
    </Switch>
  );
}

ProtectedRouter.propTypes = {
  redirectRoute: PropTypes.string,
};
