import { Colors } from '@root/core/src/utils/styles';
import { Shadows, StyleSheet, Theme } from '@root/core/src/utils/styles';

export const sectionWithHeaderSeparatorStyles = StyleSheet.create({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    background: Colors.white(),
    border: `1px solid ${Colors.DEPRECATED_blackShadow()}`,
    ...Shadows.accountCardShadow(),
    flexGrow: 1,
    margin: 100,
    minWidth: 700,
  },
  sectionHeader: {
    ...Theme.cardContent({
      black: true,
    }),
    fontSize: 14,
    paddingTop: 30,
    paddingLeft: 30,
    fontWeight: 'bold',
  },
  separator: {
    borderTop: `solid 1px ${Colors.DEPRECATED_shadow(0.1)}`,
    width: 'inherit',
    marginTop: 10,
    marginBottom: 20,
  },
  contentHeader: {
    ...Theme.heading1(),
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  table: {
    width: '70%',
    alignSelf: 'center',
  },
  tableSection: {
    padding: 20,
  },
  tableColumnContainer: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  tableColumnContents: {
    padding: 10,
    margin: 0,
  },
});
