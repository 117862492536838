import Datepicker from '@root/core/src/components/datepicker';
import DriverStatesCsvLink from '@root/root-enterprise.com/src/components/driver-states-csv-link';
import React, { useState } from '@root/vendor/react';
import vectorStrokeIcon from '@root/root-enterprise.com/src/assets/icons/vector-stroke.svg';
import { dayPickerInputStyles, dayPickerStyles } from '@root/root-enterprise.com/src/utils/datepicker-styles';
import { sectionWithHeaderSeparatorStyles } from '@root/root-enterprise.com/src/utils/section-styles';

export default function ViewPreviousDriverLists() {
  const styles = sectionWithHeaderSeparatorStyles;

  const [selectedDateForPreviousList, setSelectedDateForPreviousList] = useState();

  const handleChangeSelectedDateForPreviousList = (date) => {
    setSelectedDateForPreviousList(date);
  };

  const datepickerDateLimit = () => {
    const date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };

  return (
    <div
      css={styles.outerContainer}
      data-testid={'previous-driver-lists-section'}
    >
      <h5 css={styles.sectionHeader}>VIEW PREVIOUS DRIVER LISTS</h5>
      <div css={styles.separator} />
      <table css={styles.table}>
        <tbody>
          <tr>
            <td>
              <div css={styles.tableColumnContainer}>
                <img
                  css={styles.tableColumnContents}
                  src={vectorStrokeIcon}
                />
                <p css={styles.tableColumnContents}>Download driver list as of</p>
                <div
                  css={styles.tableColumnContents}
                  data-testid={'previous-driver-lists-datepicker'}
                >
                  <Datepicker
                    dateLimit={datepickerDateLimit()}
                    inputId={'previous-driver-list-date'}
                    inputStyles={dayPickerInputStyles}
                    label={''}
                    onDateChange={handleChangeSelectedDateForPreviousList}
                    pickerStyles={dayPickerStyles}
                    selectedDate={selectedDateForPreviousList}
                  />
                </div>
                <DriverStatesCsvLink
                  asOfDate={selectedDateForPreviousList}
                  buttonText={'Download'}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
