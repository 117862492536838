import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default class ErrorSummary extends Component {
  static propTypes = {
    children: PropTypes.node,
    heading: PropTypes.string.isRequired,
    message: PropTypes.string,
  }

  render() {
    return (
      <div css={styles.container}>
        <h2 css={styles.heading}>{this.props.heading}</h2>
        <p css={styles.message}>{this.props.message}</p>
        {this.props.children}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightPink(),
    padding: '15px 20px',
    marginBottom: '20px',
    ...Theme.roundedCorners(),
  },

  heading: {
    ...Theme.boldCaptionTitle(),
  },

  message: {
    ...Theme.caption(),
    marginBottom: 0,
  },
});
