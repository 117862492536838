import EditDriverForm from '@root/root-enterprise.com/src/components/edit-driver-form';
import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';
import { getDriver } from '@root/root-enterprise.com/src/api/root-server';
import { useHistory } from '@root/core/src/components/route';

function useGetDriver(id) {
  const [driver, setDriver] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await NetworkService.request(getDriver, id);

      setDriver(data.driver);
    };

    fetchData();
  }, [id]);
  return driver;
}

export default function EditDriver({ id }) {
  const history = useHistory();
  const driver = useGetDriver(id);

  return (
    <>
      {driver && (
        <div
          css={styles.container}
          data-testid={'edit-driver'}
        >
          <EditDriverForm
            driver={driver}
            onCancel={() => history.push('/drivers')}
            onSubmit={() => history.push('/drivers')}
          />
        </div>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    marginTop: 63,
  },
});

EditDriver.propTypes = {
  id: PropTypes.string.isRequired,
};
