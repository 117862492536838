import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useRef, useState } from '@root/vendor/react';
import moment from 'moment';
import { BUTTON_SIZE_SMALL, BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button/styles';
import { Button } from '@root/core/src/components/button/button';
import { CSVLink } from 'react-csv';
import { StyleSheet } from '@root/core/src/utils/styles';
import { getDriverList } from '@root/root-enterprise.com/src/api/root-server';

export default function DriverStatesCsvLink({ asOfDate = null, buttonText = 'Download CSV' }) {
  const csvLinkRef = useRef();
  const [currentData, setCurrentData] = useState('');

  const getDriversList = () => {
    let formattedDate = null;
    if (asOfDate) {
      formattedDate = moment(asOfDate).format('YYYY-MM-DD');
    }

    NetworkService.request(getDriverList, formattedDate).then((response) => {
      setCurrentData(response.data);
      csvLinkRef.current.link.click();
    });
  };

  const filename = () => {
    const filenamePrefix = 'driver_billing_status_report_';

    let date;
    if (asOfDate) {
      date = moment(asOfDate);
    } else {
      date = moment();
    }

    return filenamePrefix + date.format('YYYYMMDD') + '.csv';
  };

  return (
    <div
      css={styles.button}
      data-testid={'driver-states-csv-link'}
    >
      <Button
        onClick={getDriversList}
        size={BUTTON_SIZE_SMALL}
        variant={BUTTON_VARIANT_SECONDARY}
      >
        {buttonText}
      </Button>
      <CSVLink
        className={'hidden'}
        data={currentData}
        filename={filename()}
        ref={csvLinkRef}
        target={'_blank'}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  button: {
    paddingBottom: 20,
    marginLeft: 20,
  },
});

DriverStatesCsvLink.propTypes = {
  asOfDate: PropTypes.object,
  buttonText: PropTypes.string,
};
