import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import NetworkService from '@root/core/src/services/network-service';
import PasswordResetHeading from '@root/password-reset/src/components/password-reset-heading';
import PropTypes from '@root/vendor/prop-types';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { driveScienceUserForgotPassword } from '@root/root-enterprise.com/src/api/root-server';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('clientId'),
  isRequired('email'),
  isValidEmail('email'),
];

export default function ForgotPassword({
  checkEmailPath, clientId, history,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const form = useForm({
    validations,
    validateOnBlur: false,
    initialValues: {
      clientId,
      email: '',
    },
  });

  const submit = async () => {
    const clientIdValue = form.getFieldProps('clientId').value;
    const emailValue = form.getFieldProps('email').value;

    setIsLoading(true);
    const submissionResult = await NetworkService.request(driveScienceUserForgotPassword, {
      clientId: clientIdValue,
      email: emailValue,
    });

    setIsLoading(false);
    if (submissionResult.isSuccess) {
      setIsServerError(false);
      history.push(checkEmailPath);
    } else {
      setIsServerError(true);
    }
  };

  return (
    <div
      css={styles.container}
      data-testid={'forgot-password-scene'}
    >
      <div css={styles.innerContainer}>
        <PasswordResetHeading
          heading={'Enter your email address.'}
          subtext={'We\'ll send you a link to reset your password.'}
        />
        {isServerError && (
          <ErrorSummary
            heading={'Weird.'}
            message={'The email isn’t working. Mind taking another look?'}
          />
        )}
        <form
          css={styles.form}
          onSubmit={form.createSubmitHandler(submit)}
        >
          <Input
            inputType={TextTypes.TEXT}
            label={'Client ID'}
            {...form.getFieldProps('clientId')}
          />
          <Input
            css={styles.emailInput}
            inputType={TextTypes.EMAIL}
            label={'Email'}
            {...form.getFieldProps('email')}
          />
          <LoaderButton
            isLoading={isLoading}
            loadingText={'Sending link...'}
          >
            {'Send link'}
          </LoaderButton>
        </form>
      </div>
    </div>
  );
}

ForgotPassword.propTypes = {
  checkEmailPath: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  history: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 63,
  },
  innerContainer: {
    width: 380,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  emailInput: {
    marginTop: 10,
  },
  form: {
    width: '100%',
  },
  link: {
    ...Theme.link(),
  },
});
