import AuthService from '@root/core/src/services/auth-service';
import Header from '@root/root-enterprise.com/src/components/header';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';
import { useHistory } from '@root/vendor/react-router-dom';
import { validateSessionToken } from '@root/root-enterprise.com/src/api/root-server';

export default function AuthController({
  ProtectedApp,
  PublicRouter,
  SceneLoader,
}) {
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const [originatingPath, setOriginatingPath] = useState();

  useEffect(() => {
    validateSessionToken()
      .then((data) => setIsAuthenticated(data?.valid || false))
      .catch(() => setIsAuthenticated(false))
      .finally(() => {
        if (!isAuthenticated && !originatingPath) {
          setOriginatingPath(history.location.pathname);
        }
        setIsValidating(false);
      });
  }, [history.location.pathname, isAuthenticated, originatingPath]);

  const passProps = {
    onLogin: () => {
      setIsAuthenticated(true);
    },
    onLogout: () => {
      AuthService.clear();
      setIsAuthenticated(false);
    },
  };

  if (isValidating) {
    return <SceneLoader />;
  }

  if (isAuthenticated) {
    return (
      <>
        <Header onLogout={passProps.onLogout} />
        <ProtectedApp
          inactiveTimeout={environment.inactiveTimeout}
          onLogout={passProps.onLogout}
          originatingPath={originatingPath}
        />
      </>
    );
  }

  return (
    <PublicRouter
      onLogin={passProps.onLogin}
    />
  );
}

AuthController.propTypes = {
  ProtectedApp: PropTypes.func.isRequired,
  PublicRouter: PropTypes.func.isRequired,
  SceneLoader: PropTypes.func.isRequired,
};
