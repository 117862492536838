import React from '@root/vendor/react';
import UpdateDriverList from '@root/root-enterprise.com/src/components/update-driver-list';
import ViewPreviousDriverLists from '@root/root-enterprise.com/src/components/view-previous-driver-lists';

export default function Drivers() {
  return (
    <div data-testid={'drivers'}>
      <UpdateDriverList />
      <ViewPreviousDriverLists />
    </div>
  );
}
