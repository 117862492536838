import AuthService from '@root/core/src/services/auth-service';
import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import NetworkService from '@root/core/src/services/network-service';
import PasswordResetHeading from '@root/password-reset/src/components/password-reset-heading';
import PropTypes from '@root/vendor/prop-types';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet } from '@root/core/src/utils/styles';
import { driveScienceUserPasswordReset } from '@root/root-enterprise.com/src/api/root-server';
import { isConfirmed, isRequired, isValidSignUpPassword } from '@root/core/src/utils/validators';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('password'),
  isValidSignUpPassword('password'),
  isConfirmed('password'),
  isRequired('passwordConfirmation'),
];

export default function PasswordReset({
  onLogin, token, initial = false,
}) {
  const [isError, setIsError] = useState(false);
  const form = useForm({
    validations,
    validateOnBlur: false,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
  });

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    const password = form.getFieldProps('password').value;
    const passwordConfirmation = form.getFieldProps('passwordConfirmation').value;

    if (password && passwordConfirmation) {
      const loginResult = await NetworkService.request(driveScienceUserPasswordReset, {
        password,
        passwordConfirmation,
        token,
      });

      if (loginResult.isSuccess) {
        AuthService.setAccessToken(loginResult.data.driveScienceAccessToken);
        onLogin();
      } else {
        setIsError(true);
      }
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <ErrorSummary
        heading={'There was an error attempting to reset your password; your reset token may have expired.'}
        message={'Please resend a password reset email and try again from that link.'}
      />
    );
  }

  return (
    <div css={styles.container}>
      <div css={styles.innerContainer}>
        <PasswordResetHeading
          heading={initial ? 'Set your password.' : 'Reset your password.'}
        />
        <form
          css={styles.form}
          onSubmit={form.createSubmitHandler(submit)}
        >
          {errorSummary}
          <Input
            autoCapitalize={'off'}
            id={'password'}
            inputType={TextTypes.PASSWORD}
            label={'New password (at least 6 characters)'}
            {...form.getFieldProps('password')}
          />
          <div css={styles.passwordConfirmation}>
            <Input
              autoCapitalize={'off'}
              id={'passwordConfirmation'}
              inputType={TextTypes.PASSWORD}
              label={'Re-enter new password'}
              {...form.getFieldProps('passwordConfirmation')}
            />
          </div>
          <LoaderButton
            isLoading={false}
            loadingText={'Changing...'}
          >
            Change password
          </LoaderButton>
        </form>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 63,
  },
  innerContainer: {
    width: 380,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  passwordConfirmation: {
    marginTop: 10,
  },
});

PasswordReset.propTypes = {
  initial: PropTypes.bool,
  onLogin: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};
