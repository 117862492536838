import errorImage from '@root/root-enterprise.com/src/assets/web-error.png';
import { Colors, Shadows, StyleSheet } from '@root/core/src/utils/styles';

export default function EmailConfirmationFailure() {
  return (
    <div
      css={styles.container}
      data-testid={'email-confirmation-failure'}
    >
      <div css={styles.innerContainer}>
        <img
          css={styles.mainImage}
          src={errorImage}
        />
        <div css={styles.textContainer}>
          <h1 css={styles.title}>Email confirmation unsuccessful</h1>
          <p>Please confirm your email from your mobile device to activate your account.</p>
        </div>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 100,
  },
  innerContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    minHeight: 450,
    maxWidth: 750,
    border: `1px solid ${Colors.gray30()}`,
    borderRadius: 5,
    margin: '0 15px',
    ...Shadows.cardShadow(),
  },
  mainImage: {
    height: 200,
    width: 200,
    marginTop: 50,
  },
  textContainer: {
    margin: '0 20px',
    textAlign: 'center',
  },
  title: {
    marginTop: 25,
    fontSize: 24,
  },
});
