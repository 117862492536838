import PasswordResetHeading from '@root/password-reset/src/components/password-reset-heading';
import RouteLink from '@root/core/src/components/route-link';
import checkmarkStroke from '@root/core/src/assets/checkmark-stroke.svg';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function CheckEmail() {
  return (
    <div
      css={styles.container}
      data-testid={'check-email-scene'}
    >
      <div css={styles.innerContainer}>
        <PasswordResetHeading
          heading={'Check your email.'}
          icon={checkmarkStroke}
          subtext={'A link to reset your password has been sent to the email you provided'}
        />
        <RouteLink
          css={styles.link}
          to={'/login'}
        >
          Sign in
        </RouteLink>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 63,
  },
  innerContainer: {
    width: 380,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    ...Theme.boldLink(),
    cursor: 'pointer',
    ':hover': {
      opacity: .75,
    },
  },
});
