import NetworkService from '@root/core/src/services/network-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';
import { getReportUrl } from '@root/root-enterprise.com/src/api/root-server';

function useGetReportUrl(id) {
  const [reportUrl, setReportUrl] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await NetworkService.request(getReportUrl, id);

      if (data) {
        setReportUrl(data.reportUrl);
      }
    };

    fetchData();
  }, [id]);
  if (reportUrl) { setTimeout(() => { redirectToDownload(reportUrl); }, 1000); }
  return reportUrl;
}

function redirectToDownload(reportUrl) {
  window.location.replace(reportUrl); /* eslint root/prevent-use-of-window-location: 0 */
}

export default function DownloadReport({ reportId }) {
  const reportUrl = useGetReportUrl(reportId);
  return (
    <>
      <div
        css={styles.container}
        data-testid={'download-report'}
      >
        {
          reportUrl ?
            <p>Redirecting you to download your report <a href={reportUrl}>here</a>...</p>
            :
            'There was an issue finding the requested report.'

        }
      </div>

    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    marginTop: 63,
  },
});

DownloadReport.propTypes = {
  reportId: PropTypes.string.isRequired,
};
