import DriverStatesCsvLink from '@root/root-enterprise.com/src/components/driver-states-csv-link';
import HeaderRow from '@root/root-enterprise.com/src/components/update-driver-list/header-row';
import NetworkService from '@root/core/src/services/network-service';
import NotificationTextRow from '@root/root-enterprise.com/src/components/update-driver-list/notification-text-row';
import React, { useState } from '@root/vendor/react';
import ResultsCsvDownloadButton from '@root/root-enterprise.com/src/components/results-csv-download-button';
import check from '@root/root-enterprise.com/src/assets/check.png';
import error from '@root/root-enterprise.com/src/assets/error.png';
import oneCircle from '@root/root-enterprise.com/src/assets/one-circle.png';
import threeCircle from '@root/root-enterprise.com/src/assets/three-circle.png';
import twoCircle from '@root/root-enterprise.com/src/assets/two-circle.png';
import vectorStrokeIcon from '@root/root-enterprise.com/src/assets/icons/vector-stroke.svg';
import { BUTTON_SIZE_SMALL, BUTTON_VARIANT_PRIMARY } from '@root/core/src/components/button/styles';
import { Button } from '@root/core/src/components/button/button';
import { StyleSheet } from '@root/core/src/utils/styles';
import { sectionWithHeaderSeparatorStyles } from '@root/root-enterprise.com/src/utils/section-styles';
import { uploadDriversList } from '@root/root-enterprise.com/src/api/root-server';

export default function UpdateDriverList() {
  const styles = sectionWithHeaderSeparatorStyles;

  const [fileUpload, setFileUpload] = useState();
  const [uploadDisabled, setUploadDisabled] = useState(true);

  const [resultsCsvData, setResultsCsvData] = useState();
  const [rowsUpdated, setRowsUpdated] = useState();
  const [errorsPresentInCsv, setErrorsPresentInCsv] = useState();
  const [networkErrorText, setNetworkErrorText] = useState();

  const onInputChange = (event) => {
    const file = event.target.files[0];
    setFileUpload(file);
    setUploadDisabled(!file);
  };

  const onFileUpload = () => {
    resetResultsData();

    const data = new FormData();
    data.append('file', fileUpload);

    NetworkService.request(uploadDriversList, data).then((response) => {
      if (response.isSuccess) {
        setResultsCsvData(response.data.data);
        setRowsUpdated(response.data.rowsUpdated);
        setErrorsPresentInCsv(response.data.errorsPresent);
      } else {
        const message = response.error.message || 'We encountered a network error when uploading your file. Please try again.';
        setNetworkErrorText(message);
      }
    });

    resetFileUploadData();
  };

  const resetFileUploadData = () => {
    setFileUpload(null);
    setUploadDisabled(true);
  };

  const resetResultsData = () => {
    setResultsCsvData();
    setRowsUpdated();
    setErrorsPresentInCsv();
    setNetworkErrorText();
  };

  const rowsUpdatedText = () => {
    if (rowsUpdated === 0) {
      return 'No drivers were updated.';
    } else {
      const middleText = rowsUpdated > 1 ? ' drivers were' : ' driver was';
      return rowsUpdated + middleText + ' successfully updated.';
    }
  };

  const rowsUpdatedImage = rowsUpdated > 0 ? check : error;

  return (
    <div
      css={styles.outerContainer}
      data-testid={'update-driver-list-section'}
    >
      <h5 css={styles.sectionHeader}>UPDATE DRIVER LIST</h5>
      <div css={styles.separator} />
      <div css={styles.contentHeader}>
        <h3>Update Your Driver List</h3>
      </div>
      <div css={customStyles.section}>
        <table css={styles.table}>
          <tbody>
            <HeaderRow
              image={oneCircle}
              text={'Download your CSV and set the "Updated Driver Status" column to "Activated" or "Deactivated" to change a driver\'s status.'}
            />
            <tr>
              <td />
              <td>
                <div css={styles.tableColumnContainer}>
                  <img
                    css={styles.tableColumnContents}
                    src={vectorStrokeIcon}
                  />
                  <p css={styles.tableColumnContents}>Download current driver list</p>
                  <DriverStatesCsvLink />
                </div>
              </td>
            </tr>
            <tr css={customStyles.emptyRow} />
            <HeaderRow
              image={twoCircle}
              text={'Upload a revised CSV by using the button below to select a file.'}
            />
            <tr>
              <td />
              <td css={[customStyles.data, customStyles.inputField]}>
                <div css={styles.tableColumnContainer}>
                  <input
                    data-testid={'file-input'}
                    onChange={onInputChange}
                    type={'file'}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td />
              <td css={customStyles.data}>
                <div css={customStyles.button}>
                  <Button
                    disabled={uploadDisabled}
                    onClick={onFileUpload}
                    size={BUTTON_SIZE_SMALL}
                    variant={BUTTON_VARIANT_PRIMARY}
                  >
                    Upload CSV File
                  </Button>
                </div>
              </td>
            </tr>
            {networkErrorText &&
              <NotificationTextRow
                image={error}
                onClick={() => setNetworkErrorText()}
                text={networkErrorText}
              />
            }
            <tr css={customStyles.emptyRow} />
            <HeaderRow
              image={threeCircle}
              text={'Download your updated results once the Download button appears.'}
            />
            {rowsUpdated >= 0 &&
              <NotificationTextRow
                image={rowsUpdatedImage}
                onClick={() => setRowsUpdated()}
                text={rowsUpdatedText()}
              />
            }
            {errorsPresentInCsv &&
              <NotificationTextRow
                image={error}
                onClick={() => setErrorsPresentInCsv()}
                text={'Errors found'}
              />
            }
            <tr>
              <td />
              <td css={customStyles.data}>
                {resultsCsvData &&
                  <ResultsCsvDownloadButton
                    data={resultsCsvData}
                    resetResultsData={resetResultsData}
                  />}
              </td>
            </tr>
            <tr css={customStyles.emptyRow} />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export const customStyles = StyleSheet.create({
  button: {
    width: 200,
    marginBottom: 20,
  },
  section: {
    minHeight: 700,
    paddingLeft: '20%',
  },
  emptyRow: {
    height: 50,
  },
  data: {
    paddingLeft: '18%',
  },
  inputField: {
    paddingTop: 20,
  },
});
