import PropTypes from '@root/vendor/prop-types';
import ProtectedRouter from '@root/root-enterprise.com/src/components/protected-router';
import React, { useEffect, useRef } from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { validateSessionToken } from '@root/root-enterprise.com/src/api/root-server';

export default function ProtectedApp({
  onLogout, originatingPath, pingInterval = 10000,
}) {
  const { trackEvent } = useAnalytics('PROTECTED_APP');
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => isMounted.current = false;
  });

  useEffect(() => {
    async function pingForToken() {
      const data = await validateSessionToken();

      if (!data.valid && isMounted.current) {
        trackEvent('LOGOUT_DUE_TO_INVALID_TOKEN');
        onLogout();
      }
    }

    const interval = setInterval(pingForToken, pingInterval);
    return () => clearInterval(interval);
  }, [onLogout, pingInterval, trackEvent]);

  return <ProtectedRouter redirectRoute={originatingPath} />;
}

ProtectedApp.propTypes = {
  onLogout: PropTypes.func.isRequired,
  originatingPath: PropTypes.string,
  pingInterval: PropTypes.number,
};
