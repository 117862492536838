import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function PasswordResetHeading({
  heading, icon, subtext,
}) {
  return (
    <div css={styles.container}>
      {icon && (
        <img
          css={styles.icon}
          src={icon}
        />
      )}
      <h1 css={styles.heading}>
        {heading}
      </h1>
      {subtext && (
        <p css={styles.subtext}>
          {subtext}
        </p>
      )}
    </div>
  );
}

PasswordResetHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  icon: PropTypes.any,
  subtext: PropTypes.string,
};

const styles = StyleSheet.create({
  container: {
    ...Responsive.md({
      marginTop: 20,
      marginBottom: 30,
    }),
  },
  heading: {
    ...Theme.heading1(),
  },
  subtext: {
    ...Theme.paragraph1(),
    marginTop: 12,
  },
  icon: {
    marginBottom: 20,
  },
});
