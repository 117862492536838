import ErrorSummary from '@root/core/src/components/error-summary';
import Input, { TextTypes } from '@root/core/src/components/input';
import LoaderButton from '@root/core/src/components/loader-button';
import PropTypes from '@root/vendor/prop-types';
import useForm from '@root/core/src/hooks/use-form';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { loginUser } from '@root/root-enterprise.com/src/services/login';
import { useState } from '@root/vendor/react';

const validations = [
  isRequired('email'),
  isValidEmail('email'),
  isRequired('clientId'),
  isRequired('password'),
];

export default function LoginForm({ clientId = '', onLogin }) {
  const [isError, setIsError] = useState(false);
  const form = useForm({
    validations,
    validateOnBlur: false,
    initialValues: {
      clientId,
      email: '',
      password: '',
    },
  });

  const submit = async (e) => {
    e.preventDefault && e.preventDefault();
    const formClientId = form.getFieldProps('clientId').value;
    const email = form.getFieldProps('email').value;
    const password = form.getFieldProps('password').value;

    if (formClientId && email && password) {
      const loginResult = await loginUser(formClientId, email, password);

      if (loginResult.isSuccess) {
        onLogin();
      } else {
        setIsError(true);
      }
    }
  };

  let errorSummary = null;

  if (isError) {
    errorSummary = (
      <ErrorSummary
        heading={'No user could be found matching this client ID, email, and password.'}
        message={'Please try again.'}
      />
    );
  }

  return (
    <div css={styles.container}>
      <form
        onSubmit={form.createSubmitHandler(submit)}
      >
        {errorSummary}
        <div css={styles.inputWrapper}>
          <Input
            autoCapitalize={'off'}
            id={'clientId'}
            inputType={TextTypes.TEXT}
            label={'Client ID'}
            {...form.getFieldProps('clientId')}
          />
        </div>
        <div css={styles.inputWrapper}>
          <Input
            autoCapitalize={'off'}
            id={'email'}
            inputType={TextTypes.TEXT}
            label={'Email'}
            {...form.getFieldProps('email')}
          />
        </div>
        <Input
          autoCapitalize={'off'}
          id={'password'}
          inputType={TextTypes.PASSWORD}
          label={'Password'}
          {...form.getFieldProps('password')}
        />
        <LoaderButton
          isLoading={form.submitting}
          loadingText={'Sending...'}
        >
          Login
        </LoaderButton>
      </form>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 380,
  },
  inputWrapper: {
    marginBottom: 10,
  },
});

LoginForm.propTypes = {
  clientId: PropTypes.string,
  onLogin: PropTypes.func.isRequired,
};
