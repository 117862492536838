import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RouteLink from '@root/core/src/components/route-link';
import environment from '@root/core/src/utils/environment';
import rootEnterpriseLogo from '@root/root-enterprise.com/src/assets/root-enterprise-logo.png';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function Header({ onLogout }) {
  const exposeReleaseInfo = environment.environment.toLowerCase() !== 'production';

  return (
    <div css={styles.header}>
      <div
        css={styles.container}
      >
        <RouteLink to={'/'}>
          <img
            css={styles.rootLogo}
            data-testid={'root-enterprise-logo'}
            src={rootEnterpriseLogo}
            title={exposeReleaseInfo ? environment.releaseSha : ''}
          />
        </RouteLink>
        {!!onLogout &&
        <RouteLink
          data-testid={'root-enterprise-logout-link'}
          onClick={onLogout}
          to={'/login'}
        >
          Logout
        </RouteLink>
        }
      </div>
    </div>
  );
}

Header.propTypes = {
  onLogout: PropTypes.func,
};

const styles = StyleSheet.create({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: 63,
    zIndex: 1,
    background: Colors.white(),
  },
  container: {
    background: Colors.white(),
    height: 60,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    ...Responsive.md({
      paddingLeft: 80,
      paddingRight: 80,
    }),
  },
  rootLogo: {
    height: 40,
  },
});
