import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export function IconChevron({
  direction = 'down', color = Colors.black(), iconStyles,
}) {
  return (
    <svg
      css={[styles.icon, styles[direction], iconStyles]}
      fill={'none'}
      height={'13'}
      viewBox={'0 0 22 13'}
      width={'22'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M21.0844 1L11.0844 11L1.08435 0.999998'}
        data-testid={'chevron'}
        stroke={color}
        strokeWidth={'1.5'}
      />
    </svg>
  );
}

const styles = StyleSheet.create({
  icon: {
    transition: 'transform 300ms ease-in-out',
  },
  up: {
    marginBottom: 3,
    transform: 'rotateX(180deg)',
  },
  left: {
    transform: 'rotate(90deg)',
  },
  right: {
    transform: 'rotate(-90deg)',
  },
});

IconChevron.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  iconStyles: PropTypes.object,
};
