import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AuthController from '@root/root-enterprise.com/src/components/auth-controller';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import PropTypes from '@root/vendor/prop-types';
import ProtectedApp from '@root/root-enterprise.com/src/components/protected-app';
import PublicRouter from '@root/root-enterprise.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import ScrollToTop from '@root/core/src/components/scroll-to-top';
import TrackVisitAttributionController from '@root/attribution/src/components/track-visit-attribution-controller';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { Suspense } from '@root/vendor/react';

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');
  useSaveTouchAttributionEffect();

  return (
    <Router>
      <ScrollToTop>
        <Suspense fallback={<SceneLoader />}>
          <TrackVisitAttributionController />
          <AuthController
            ProtectedApp={ProtectedApp}
            PublicRouter={PublicRouter}
            SceneLoader={SceneLoader}
          />
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({ routerComponent }) {
  return (
    <ErrorBoundary>
      <AnalyticsProvider eventPrefix={'ROOT_ENTERPRISE'}>
        <App routerComponent={routerComponent} />
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  routerComponent: PropTypes.func.isRequired,
  routerProps: PropTypes.object,
};

const ROOT_ENTERPRISE_APP_DOMAIN = 'root-enterprise.com';

export { ROOT_ENTERPRISE_APP_DOMAIN };
