import CheckEmail from '@root/root-enterprise.com/src/scenes/check-email';
import CohortLinkFailure from '@root/root-enterprise.com/src/components/cohort-link-failure';
import EmailConfirmationFailure from '@root/root-enterprise.com/src/components/email-confirmation-failure';
import ForgotPassword from '@root/root-enterprise.com/src/scenes/forgot-password';
import Header from '@root/root-enterprise.com/src/components/header';
import Login from '@root/root-enterprise.com/src/scenes/login';
import PasswordReset from '@root/root-enterprise.com/src/scenes/password-reset';
import PrivacyPolicy from '@root/root-enterprise.com/src/scenes/privacy-policy';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import STORE_MAPPINGS from '@root/root-enterprise.com/src/constants/parsnip-app-store-mappings';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { isAndroid, isIos } from '@root/core/src/utils/detect-mobile-browser';
import { useHistory, useLocation } from '@root/vendor/react-router-dom';

const PublicRouter = ({ onLogin }) => {
  const history = useHistory();
  const location = useLocation();
  const { trackEvent } = useAnalytics('ROUTER', false);
  const searchParams = new URLSearchParams(location.search);
  const clientId = searchParams.get('client_id') || '';
  const token = searchParams.get('password_reset_token') || '';
  const initial = searchParams.get('initial') || false;

  function redirectToStore(client, intendedRoute) {
    if (Object.keys(STORE_MAPPINGS).includes(client)) {
      const appStoreId = STORE_MAPPINGS[client].ios;
      const playStoreId = environment.environment === 'staging' ? STORE_MAPPINGS[client].android.staging : STORE_MAPPINGS[client].android.production;

      if (playStoreId && isAndroid()) {
        trackEvent(`REDIRECT_TO_PLAY_STORE_FROM_${intendedRoute}`);
        window.location.assign(`https://play.google.com/store/apps/details?id=${playStoreId}`); /* eslint root/prevent-use-of-window-location: 0 */
      } else if (appStoreId && isIos()) {
        trackEvent(`REDIRECT_TO_APP_STORE_FROM_${intendedRoute}`);
        window.location.assign(`https://apps.apple.com/us/app/id${appStoreId}?mt=8`); /* eslint root/prevent-use-of-window-location: 0 */
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <Switch>
      <Route
        path={'/login'}
        render={() => {
          return (
            <>
              <Header />
              <Login
                clientId={clientId}
                forgotPasswordPath={'/forgot-password'}
                onLogin={onLogin}
              />
            </>
          );
        }}
      />
      <Route
        path={'/forgot-password'}
        render={() => {
          return (
            <>
              <Header />
              <ForgotPassword
                checkEmailPath={'/check-email'}
                clientId={clientId}
                history={history}
              />
            </>
          );
        }}
      />
      <Route
        path={'/check-email'}
        render={() => {
          return (
            <>
              <Header />
              <CheckEmail />
            </>
          );
        }}
      />
      <Route
        path={'/password-reset'}
        render={() => {
          return (
            <>
              <Header />
              <PasswordReset
                initial={initial}
                onLogin={onLogin}
                token={token}
              />
            </>
          );
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/confirmation'}
        render={({ match }) => {
          if (!redirectToStore(match.params.clientId, 'EMAIL_CONFIRMATION')) {
            return (
              <EmailConfirmationFailure />
            );
          }
        }}
      />
      <Route
        path={'/clients/:clientId/drivers/cohort'}
        render={({ match }) => {
          if (!redirectToStore(match.params.clientId, 'COHORT_LINK')) {
            return (
              <CohortLinkFailure />
            );
          }
        }}
      />
      <Route
        path={'/privacy-policy'}
        render={() => {
          return (
            <>
              <Header />
              <PrivacyPolicy />
            </>
          );
        }}
      />
      <Redirect to={'/login'} />
    </Switch>
  );
};

PublicRouter.propTypes = {
  onLogin: PropTypes.func.isRequired,
};

export default PublicRouter;
