import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { sectionWithHeaderSeparatorStyles } from '@root/root-enterprise.com/src/utils/section-styles';

export default function HeaderRow({ image, text }) {
  const styles = sectionWithHeaderSeparatorStyles;

  return (
    <tr>
      <td>
        <img
          css={styles.tableColumnContents}
          src={image}
        />
      </td>
      <td><p css={styles.tableColumnContents}>{text}</p></td>
    </tr>
  );
}

HeaderRow.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
};
