import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import chevronLeft from '@root/core/src/assets/chevron-left.svg';
import chevronRight from '@root/core/src/assets/chevron-right.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export const dayPickerStyles = StyleSheet.create({
  container: {
    display: 'inline-block',
    fontSize: 14,
    color: Colors.gray60(),
    backgroundColor: Colors.white(),
    position: 'absolute',
    marginTop: 5,
    width: '100%',
    ...Responsive.sm({
      fontSize: 14,
      width: 'auto',
    }),
  },
  wrapper: {
    position: 'relative',
    flexDirection: 'row',
    paddingBottom: 20,
    userSelect: 'none',
    ':focus': {
      outline: 0,
    },
  },
  interactionDisabled: {
    display: 'none',
  },
  navBar: {},
  navButtonPrev: {
    position: 'absolute',
    top: 18,
    display: 'inline-block',
    width: 20,
    height: 20,
    backgroundPosition: 'center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    left: 16,
    backgroundImage: `url(${chevronLeft})`,
    ':hover': {
      opacity: '0.8',
    },
  },
  navButtonNext: {
    position: 'absolute',
    top: 18,
    display: 'inline-block',
    width: 20,
    height: 20,
    backgroundPosition: 'center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    right: 16,
    backgroundImage: `url(${chevronRight})`,
    ':hover': {
      opacity: '0.8',
    },
  },
  navButtonInteractionDisabled: {},
  months: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    boxShadow: `0 5px 20px 3px ${Colors.DEPRECATED_blackShadow()}`,
    border: `solid 1px ${Colors.DEPRECATED_blackShadow()}`,
    borderRadius: 3,
  },
  month: {
    display: 'table',
    margin: '16px 10px 5px 10px',
    borderCollapse: 'separate',
    userSelect: 'none',
    ...Responsive.sm({
      borderSpacing: '10px',
    }),
    borderSpacing: '2px',
  },
  caption: {
    display: 'table-caption',
    marginBottom: '15px',
    padding: '0 25px',
    textAlign: 'center',
  },
  weekdays: {
    display: 'table-header-group',
  },
  weekdaysRow: {
    display: 'table-row',
  },
  weekday: {
    display: 'table-cell',
    padding: 10,
    textAlign: 'center',
    ':nth-child(1n) > abbr[title]': {
      borderBottom: 'none',
      textDecoration: 'none',
      cursor: 'default',
    },
  },
  body: {
    display: 'table-row-group',
  },
  week: {
    display: 'table-row',
    ':nth-child(1n) > .past': {
      color: Colors.gray40(),
    },
    ':nth-child(1n) > .outside': {
      color: Colors.gray40(),
    },
  },
  day: {
    display: 'table-cell',
    borderRadius: 3,
    verticalAlign: 'middle',
    textAlign: 'center',
    ':not(.past):not(.outside)': {
      cursor: 'pointer',
      ':hover': {
        backgroundColor: Colors.gray40(),
        color: Colors.white(),
      },
    },
  },
  footer: {},
  todayButton: {},
  today: {},
  selected: {
    ':hover': {
      backgroundColor: Colors.rootOrange(),
    },
    ':not(.disabled):not(.outside):not(:past)': {
      backgroundColor: Colors.rootOrange(),
      color: Colors.white(),
    },
  },
  disabled: {
    color: Colors.gray40(),
    cursor: 'default',
  },
  outside: {
    color: `${Colors.gray40()} !important`,
    backgroundColor: `${Colors.white()} !important`,
    cursor: 'default !important',
    ':hover': {
      color: `${Colors.gray40()} !important`,
      backgroundColor: `${Colors.white()} !important`,
    },
  },
});

export const dayPickerInputStyles = StyleSheet.create({
  container: {
    width: '100%',
    ':nth-child(1n) > input': {
      height: 34,
      width: 155,
      paddingRight: 42,
      paddingLeft: 10,
      fontSize: 14,
      borderRadius: 3,
      border: `solid 1px ${Colors.gray40()}`,
      borderColor: Colors.gray40(),
      textAlign: 'left',
      color: Colors.gray60(),
      cursor: 'pointer',
      '::placeholder': {
        color: Colors.gray50(),
      },
      ':focus': {
        outline: 'none',
        borderColor: Colors.black(),
      },
      ...Responsive.lessThanXl({
        width: '100%',
      }),
    },
  },
  iconLabel: {
    top: 4,
    paddingRight: 10,
  },
  errorContainer: {
    ':nth-child(1n) > input': {
      border: `solid 1px ${Colors.error()}`,
      borderColor: Colors.error(),
      color: Colors.error(),
      '::placeholder': {
        color: Colors.error(),
      },
    },
  },
  overlay: {
    marginTop: 0,
    position: 'relative',
    zIndex: ZIndex.DATEPICKER_OVERLAY,
  },
  overlayWrapper: {},
});
